* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

.header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  width: 100%;
  z-index: 96;
  background-color: white;
}

.icon .shopsy img {
  max-width: 100%;
  height: auto;
}

.nav-container {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.auth-buttons {
  margin-top: 8px;
  display: flex;
  gap: 1vh;
}
.start-selling {
  height: 48px;
  width: 150px;
  color: #ffffff;
}
.image-container {
  position: relative;
  width: 100%;
  height: 400px;  /* Set the height of the container */
  overflow: hidden; /* Hide any overflow */
}

.image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #ff9a9e, #fad0c4); /* Example gradient */
  z-index: 1;  /* Place the background behind the image */
  animation: gradientAnimation 5s infinite; /* Animation */
}

.img-fluid {
  position: relative;
  z-index: 2; /* Place the image above the background */
  width: 100%;
  height: auto; /* Maintain aspect ratio */
}

@keyframes gradientAnimation {
  0% {
      background-position: 0% 0%;
  }
  50% {
      background-position: 100% 100%;
  }
  100% {
      background-position: 0% 0%;
  }
}

.auth-buttons .start-selling {
  background-color: #027CD5;
  border: unset;
  color: #ffffff;
  font-weight: normal;
}
.item-list {
  margin: 0 15px;
  cursor: pointer;
}

.item-list h5 {
  font-size: medium;
  font-weight: 500;
}

.auth-buttons button {
  margin-left: 10px;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
}

.start-selling {
  background-color: yellow;
  border: none;
}

.shopsy {
  display: flex;
  justify-content: center;
  align-items: center;
}
.shopsy img {
  height: 40px;
}
.loginbtn {
  border: unset;
  background-color: unset;
  font-size: 13.5px;
  font-weight: 600;
}
.hero-section {
  width: 100%;
  height: auto;
}
.hero-section2 {
  height: 480px;
  background-image: url("../../public/assets/images/sell9.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 22px;
}
.imgbox {
  height: 50px;
  width: 50px;
}

.existingtext {
  font-size: 10px;
  padding-left: 5px;
  font-weight: 500;
  width: 60%;
}
.loginbtn_container {
  height: 5vh;
  width: 16vh;
  border: 1px solid #027cd5;
  border-radius: 8px;
  color: #027cd5;
  background-color: #fff;
}

.hero-section .card {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  width: 90%;
  bottom: 20px;
  height: 140px;
}

.stat-item h4 {
  margin: 0;
  font-size: 1.5rem;
}

.stat-item p {
  margin: 0;
  font-size: 1rem;
}

.second {
  background-color: #ffffff;
  height: 500px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: auto;
  margin-left: auto;
  width: 90%;
}
.card-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 110px;
}
.text-secondary {
  font-size: 15px;
  margin-bottom: 0px;
  /* margin-left: 45px; */
}
.text-secondary1 {
  color: #6c757d;
  font-size: 12.5px;
  font-weight: 500;
  width: 65%;
}
.stat-item{
  font-size: 22px;
}
.whydotext {
  font-size: 28px;
}

.divider {
  height: 50px;
  width: 1px;
  background-color: #e0e0e0;
  margin: 0 20px;
}
.text-muted{
  font-weight: 460;
  font-size: 16px;
}

/* .commision {
  gap: 1rem;
} */

.commision .card {
  width: 100%;
  height: auto;
}

.third {
  background-color: #f7f5ff;
  height: 400px;
}

.slider {
  margin-top: 50px;
}

.slider .slick-prev:before,
.slick-next:before {
  color: black;
}

.slider .slick-prev:before,
.slick-next:before {
  color: black;
}

.fourth {
  background-color: #f2faff;
  padding: 15px;
}
.fourth h4 {
  font-weight: 500;
}
.four {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}
.fourthcardcontainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
}
.fourthgrid .card {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.fourthgrid .card-text {
  padding: 10px;
}
.fourthgrid .card-text p {
  font-size: 12.6px;
}
.mobile-v {
  display: none;
}

#desk-v {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 100%;
  background-color: #ffffff;
  padding: 16px;
}
/* .img-fluid{
    margin-top: 50px;
  } */
.fityh {
  background-color: #fff;
  width: 90%;
  height: 100%;
  padding: 20px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}
.accesstext {
  font-size: 26px;
  /* margin-left: 38px; */
  font-family: "Roboto" sans-serif;
  letter-spacing: 0.5;
}
.accesstext h4 {
  background-color: #000000;
  font-weight: 500;
}
.cardcontainer {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  width: 350px;
  height: 140px;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 4px;
  margin-top: 10px;
}
.fullfilmenttext {
  padding-left: 10px;
  font-size: 15px;
}
.worriedtext {
  font-size: 12.5px;
  color: #666;
}
.learntext {
  font-size: 13px;
  color: #666;
}
.commision {
  /* margin-left: 40px; */
  /* margin-right: 15px; */
}
.heading {
  margin-left: 40px;
}
.desc {
  /* margin-left: 39px; */
  /* color: #cecece; */
  color: #6c757d;
  font-size: 12.3px;
  font-weight: 500;
  width: 70%;
}

.cardimg {
  height: 180px;
  width: auto;
}

#aunty {
  margin-top: 50px;
  width: 100%;
  height: 100%;
}


.thousand {
  margin-top: 60px;
}
.thousand .card {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

@media (max-width: 320px) {
  .custom-drawer .custom-drawer-body {
    width: 100px !important;
  }

  .ant-drawer .ant-drawer-content {
    width: 75%;
  }

  .ant-drawer-left > .ant-drawer-content-wrapper {
    box-shadow: none;
  }

  @keyframes slideInLeft {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideOutLeft {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(-100%);
    }
  }

  .custom-drawer-enter {
    animation: slideInLeft 0.6s forwards;
  }

  .custom-drawer-leave {
    animation: slideOutLeft 0.3s forwards;
  }
}

.popularcontainer {
  display: flex;
  justify-content: center;
}
.text {
  color: #fff;
  font-weight: 500;
}
.container-fluid {
  border-bottom: 1px solid #fff;
  padding-bottom: 10px;
  margin-top: 20px;
}
.container-fluid1 {
  margin-top: 20px;
}
ul li {
  list-style: none;
  padding-top: 2px;
}
ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 12px;
}
.listcontainer1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}
.listcontainer1 ul li {
  list-style: none;
  padding-top: 5px;
}
.listcontainer1 ul li a {
  color: #fff;
  text-decoration: none;
}
.text1 {
  color: #fff;
}
.rowancard {
  display: flex;
  gap: 3.2vh;
  flex-wrap: wrap;
}
.card-upper {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fourthgrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
.accesscontainer {
  display: flex;
  gap: 4vh;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .card {
    width: 100%;
    display: flex;
    flex-direction: row !important;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  }
  .card .cardimg {
    width: 30%;
    height: 100%;
    display: flex;
  }
  .card .cardimg {
    width: 30%;
    display: flex;
  }
  .card .card-text {
    display: flex;
    width: 70%;
  }
  .fourth {
    margin-top: 30px;
  }
  .btncontainer {
    width: 90%;
  }

  .hero-section2 {
    height: 200px;
    width: 100%;
    background-image: url("../../public/assets/images/sell9.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 100%;
    margin-top: 10px;
    padding: unset;
  }
  .hero-section2 h1 {
    font-size: medium;
    margin-right: 10px;
    text-justify: auto;
    margin-left: -20px;
  }
  .thousand {
    margin-top: unset;
  }
  .thousand .card {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
  .mobile-v {
    display: block;
  }

  .header #desk-v {
    display: none;
  }

  .third {
    height: 100%;
  }

  .second {
    height: 100%;
  }

  /* .commision {
  } */
  .heading {
    margin-left: 5px;
  }
  .desc {
    margin-left: 5px;
    width: 100%;
  }
  .heading1 {
    margin-top: 10px;
    margin-left: 15px;
  }
  .desc1 {
    margin-left: 15px;
    width: 100%;
  }
  .fourthcardcontainer {
    justify-content: flex-start;
  }
  /* .cardimg {
      height: 250px;
    } */
  .whydotext {
    font-size: 22px;
    width: 100%;
    margin-left: -10px;
  }
  #aunty {
    margin-top: 2px;
    /* margin-left: 50px; */
  }
  #ani{
    display: none;
  }
  .slider {
    width: 100%;
    margin: 0;
  }

  .slider .card {
    width: 100%;
  }

  .fourth .card {
    width: 100%;
    /* gap: 5px; */
  }
  .second {
    background-color: #ffffff;
    padding: unset;
    height: 100%;
    width: 100%;
  }

  .profile {
    position: sticky;
    position: -webkit-sticky;
    width: 100%;
    top: 0;
    left: 0;
    height: 50px;
    display: block;
    z-index: 97;
  }

  .ant-drawer .ant-drawer-body {
    padding: 0%;
  }

  .threebars {
    font-size: 30px;
  }
  .accesstext {
    font-size: 22px;
  }
  .text-secondary {
    /* margin-left: -10px; */
    font-size: 13px;
  }
  .text-secondary1 {
    font-size: 10px;
    width: 100%;
  }
  .second {
    margin: 10px 0;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: unset;
  }

  /* Mobile_Header.css */

  .mob-sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .header-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding-right: 15px;
    height: 40px;
  }

  .existingtext {
    /* Styles for existing text */
  }

  .loginbtn_container {
    /* Styles for login button */
  }

  .row-wrapper {
    box-shadow: rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    background-color: #ffffff;
    height: 50px;
  }

  .profile-image {
    line-height: 1;
    margin-right: 45px;
  }

  .start-selling-btn {
    /* Styles for start selling button */
  }

  .menu-item {
    /* Styles for menu items */
  }

  @keyframes slideInLeft {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideOutLeft {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(-100%);
    }
  }

  .custom-drawer-enter {
    animation: slideInLeft 0.5s forwards;
  }

  .custom-drawer-leave {
    animation: slideOutLeft 0.3s forwards;
  }

  .menu-list {
    padding: 0 20px;
  }

  .menu-item {
    display: flex;
    align-items: center;
    padding: 8px 0;
    border-bottom: 0.6px inset #eeeeee;
    font-size: 16px;
  }

  .menu-item span {
    margin-left: 10px;
    color: #333;
  }

  .menu-item svg {
    font-size: 18px;
    color: #fff;
  }

  .divider {
    display: none;
  }

  .fourthgrid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2vh;
  }
  .four {
    width: 100%;
    padding: unset;
  }
  .fityh {
    width: 100%;
  }
  #aunty {
    display: none;
  }
  .loginbtn_container {
    background-color: #027CD5;
    border: unset;
    color: #ffffff;
    font-weight: normal;
    width: 86px;
    height: 28px;
    border-radius: 5px;
  }
  .accesscontainer {
    width: 100%;
    justify-content: center;
  }
  .hero-section .card {
    padding: unset;
  }
  .rowancard{
        gap:unset ;
  }
  .cardcontainer{
    width: auto;
  }
}
@media screen and (min-width: 300px) and (max-width: 475px) {
  .text {
    color: #fff;
    font-weight: 500;
    font-size: 15px;
  }
}
