body {
  width: 100%;
  min-height: 100vh;
  overflow: hidden scroll;
}
/* ::-webkit-scrollbar {
  display: none;
} */
.listing_management_top {
  width: 100%;
  background-color: rgba(205, 204, 204, 0.372);
  display: flex;
  overflow: hidden;
}
.left {
  width: 60%;
  padding: 0.8rem 1.8rem;
  display: flex;
  flex-direction: column;
}
.left p {
  color: blue;
  font-size: 1.1rem;
}
.left :nth-child(2) {
  color: black;
  font-size: 1.5rem;
}
.right {
  width: 40%;
  padding: 20px 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.right .mobilesearch {
  display: none;
}
.activediv {
  font-size: 16px;
  height: 70px;
}

.actives {
  padding: 30px;
  box-shadow: 0px 0.5px 4px gray;
}
.radios {
  width: 70%;
  display: flex;
  justify-content: space-between;
}
.forFour {
  display: flex;
  flex-wrap: wrap;
}

.bulkactions {
  width: 100%;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bulksubdiv {
  width: 96.5%;
  min-height: 100vh;
  padding: 10px 0;
}
.dropdown {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}
.firstHalf {
  width: 70%;
  height: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}
.secondHalf {
  width: 25%;
  height: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.productdetails {
  width: 100%;
  min-height: 100vh;
  padding: 10px 0;
}
.productdropdown {
  width: 100%;
  height: 80px;
  display: flex;
}
.fordropdowndivide {
  width: 95%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  /* justify-content: space-evenly; */
}
.topdiv {
  width: 100%;
  height: 100%;
  max-height: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.topdiv .dropcontent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}
.bottomdiv {
  width: 40%;
  height: 45%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.dropsdesk {
  display: none;
}
.bottomdiv .apply {
  color: rgba(0, 0, 255, 0.213);
}
.flipkartplusdesk {
  display: none;
}
.forFourmoile {
  display: none;
}

@media screen and (min-width: 300px) and (max-width: 768px) {
  .listing_management_top {
    display: flex;
    justify-content: space-between;
  }
  .left {
    width: 35%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .left p {
    font-size: 15px;
  }
  .right .btn {
    font-size: 10px;
  }
  .left p span {
    font-size: 12px;
  }
  .left :nth-child(2) {
    font-size: 11px;
  }
  .right .desktopsearch {
    display: none;
  }
  .right .btn {
    background-color: blue;
  }
  .right .mobilesearch {
    display: block;
  }
  .right {
    width: 47.5%;
    display: flex;
    gap: 5px;
    justify-content: space-evenly;
  }
  .btn {
    width: 120px;
    font-size: 10px;
    text-align: center;
  }
  .actives {
    padding: 6px;
  }
  .radios {
    width: 100%;
    display: flex;
    gap: 5px;
    flex-direction: column;
  }
  .forFour {
    display: none;
  }
  .forFourmoile {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    background-color: red;
  }
  .forFour div {
    width: 100%;
    max-width: 150px;
  }
 .forFourmoile .activediv {
    width: 22vh;
  }
  .activediv span {
    font-size: 12px;
  }

  .dropdown {
    width: 100%;
    min-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .firstHalf {
    width: 100%;
    min-height: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 5px;
  }
  .firstHalf div button {
    width: 100px;
  }
  .activesdesktop {
    display: none;
  }

  .firstHalf div button {
    width: 120px;
    font-size: 10px;
  }
  .dropsdesk {
    display: flex;
  }
  .secondHalf {
    width: 100%;
    min-height: 30%;
    gap: 5px;
    display: none;
  }

  .secondHalf div button {
    width: 120px;
    font-size: 10px;
  }
  .fordropdowndivide {
    width: 100%;
  }
  .productdropdown {
    min-height: 300px;
  }
  .forfiltericon {
    display: none;
    padding-top: 10px;
  }
  .topdiv {
    width: 100%;
    min-height: 80%;
    display: flex;
  }
  .dropcontent {
    padding: 0;
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
  }
  .dropcontent div {
    padding: 0;
    max-width: 150px;
    display: flex;
  }
  .dropcontent div button {
    width: 165px;
  }
  .dropcontentbtn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dropcontentbtn span {
    font-size: 10px;
  }
  .bottomdiv {
    padding: 0;
    width: 100%;
    max-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .fordesktopprocument {
    display: none;
  }
  .bottomdiv :nth-child(2) {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
  }
  .bottomdiv div button {
    width: 150px;
    flex-grow: 1rem;
  }
}
@media screen and (width: 375px) {
  .bottomdiv :nth-child(2) {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
  }
}
@media (min-width: 400px) and (max-width: 450px) {
  .bottomdiv :nth-child(2) {
    width: 84%;
    display: flex;
    flex-wrap: wrap;
  }
}
