.boxmaincontainer2 {
  width: 50%;
}
.checkicon1 {
  color: red;
  font-size: 15px;
}
.main-container {
  /* padding: 0 15px; */
}
.container-one {
  /* padding: 10px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.item {
  cursor: pointer;
}
.item h3 {
  font-weight: 500;
  color: #000;
  border-bottom: 1px inset;
  font-size: 15px;
  /* text-align: end;
    padding: 5px; */
  /* width: 20vh; */
  padding-left: 5vh;
  padding-bottom: 1vh;
}
.topselling {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
}

.brandtext1 {
  font-size: 9px;
  font-weight: 500;
  width: 400px;
  background-color: #ffd591;
  padding: 1%;
}

.activeItem {
  background: #d6e0f5;
}
@media screen and (max-width: 768px) {
  .boxmaincontainer2 {
    width: 100%;
  }
  .topselling {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30vh;
  }
}
