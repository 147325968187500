.homecontent {
  width: 100%;
  min-height: 100vh;
  /* background-color: rgb(240, 230, 230); */
  display: flex;
  gap: 20px;
  /* padding: 30px 0px; */
}

.status {
  width: 25%;
  min-height: 100vh;
  background-color: #f2f2f28f;
}
.statusmaincontainer{
  background-color: #fff;
  /* border: 1px solid black; */
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  width: 90%;
}
.statustext{
  font-size: 16px;
  font-family: 'Roboto' sans-serif;
  font-weight: 600;
  width: 100%;
  color: #2874f0;
}
.statustext1{
  font-size: 11px;
  color: #000;
  font-weight: 600;
}
.statusmaincontainer1{
  display: flex;
}
.statusmaincontainer2{
  width: 100%;
  margin-top: 20px;
}
.dispatchtext{
  font-size: 12px;
}
.goldstatustext{
  font-size: 10px;
  color: #666;
  width: 80px;
  margin-left: 8px;
}
.promotiontext{
  color: #2874f0;
  font-size: 13px;
  border-bottom: .5px solid #ccc;
  padding-bottom: 10px;
 
}
.promotiontext1{
  color: #000;
  font-size: 13px;
  border-bottom: .5px solid #ccc;
  padding-bottom: 10px;
  padding-top: 10px;
}
.reportmaincontainer{
  background-color: #fff;
  /* border: 1px solid black; */
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
}
.needhelptext{
  font-size: 12px;
  padding-left: 8px;
}
.monthtext{
  height: auto;
  width: 100px;
  /* border: 1px solid #000; */
  border-radius: 10px;
  font-size: 10px;
  padding: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #2874f0;
  margin-left: 15px;
  margin-top: -10px;
}
.sellerfull_main {
  width: 50%;
  min-height: 100vh;
}

.grossrevenue {
  width: 25%;
  min-height: 100vh;
  background-color: #f2f2f28f;
}

.sellerfullfilledorder {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.innerseller {
  height: 15%;
  width: 100%;
  background-color: white;
  border-radius: 2px;
}

.fulfilled_orders {
  padding: 10px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.fulfilled_orders p {
  font-size: 13px;
}

.fulfilled_orders h4,
.innerlistings h4 {
  color: #2874f0;
  letter-spacing: 1px;
}

.Orders {
  width: 100%;
  min-height: 60%;
  display: flex;
  justify-content: space-evenly;
}
.Orders1 {
  width: 100%;
  min-height: 60%;
  display: flex;
  justify-content: space-evenly;
}

.Orders div {
  margin-top: 10px;
  width: 50%;
  min-height: 50%;
  /* padding: 20px; */
  display: flex;
  border: 1px solid gainsboro;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Orders1 div {
  margin-top: 10px;
  width: 50%;
  min-height: 50%;
  /* padding: 20px; */
  display: flex;
  border: 1px solid gainsboro;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.innerorder {
  font-size: 23px;
  font-weight: 600;
}
.innerorder1 {
  font-size: 23px;
  font-weight: 600;
}

.growth_center {
  width: 100%;
  min-height: 100vh;
  border-radius: 2px;
}

.innercontents {
  padding-top: 10px;
}

.innergrowth_center {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.growthnewone {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
}

.threegrowthcentral {
  width: 100%;
  min-height: 50%;
  display: flex;
  flex-direction: column;
}

.newgrowthcenter {
  width: 100%;
  padding: 15px 0px 5px 0px;
  height: 15%;
  background-color: rgb(250, 212, 148);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  padding: 5px;
}

.newgrowthcenter button {
  color: white;
  border: 1px solid rgb(255, 165, 0);
  background-color: rgb(255, 165, 0);
  border-radius: 4px;
}

.growthcentral_sub {
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px 0px 0px 0px;
  background-color: white;
}

.innnergrowth {
  font-size: 18px;
}

.numbers {
  color: #2874f0;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.thirdgrowth_central {
  display: flex;
  gap: 240px;
}

.secondgrowth_central {
  display: flex;
  gap: 80px;
}

hr {
  width: 100%;
  border: 1px solid gainsboro;
}

.main_returns {
  width: 100%;
  height: 50%;
  background-color: white;
}

.listings {
  width: 100%;
  height: 110px;
  background-color: white;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.innerlistings {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: white;
}

.innerlistings p {
  font-size: 14px;
}

.subreturns {
  height: 90px;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.subreturns div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid gainsboro;
  width: 50%;
  height: 100%;
}

.returnNumbers {
  font-size: 23px;
  font-weight: 600;
}

.payments {
  width: 100%;
  height: 150px;
  padding-top: 10px;
  border: 1px solid gainsboro;
  border-radius: 2px;
  background-color: white;
}

.paymentscontent {
  padding-left: 10px;
}

.next_payment {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  /* gap: 230px; */
  /* align-items: center; */
  padding: 5px;
}

.payments hr {
  width: 100%;
}

.inner_next_payment {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  line-height: 25px;
}

.prepaid {
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
  gap: 400px;
}

.colorpayments {
  color: #2874f0;
  font-size: 25px;
}

/* ::-webkit-scrollbar {
  display: none;
} */

.subnew {
  width: 50px;
  height: 25px;
  color: white;
  border: 1px solid rgb(255, 165, 0);
  background-color: rgb(255, 165, 0);
  border-radius: 4px;
  margin-bottom: 15px;
}
.subnew2{
  width: 50px;
  height: 25px;
  color: white;
  border: 1px solid rgb(255, 165, 0);
  background-color: rgb(255, 165, 0);
  border-radius: 4px;
}

.buttonsubgrowth {
  display: flex;
  gap: 10px;
  align-items: center;
}

.divtagmobile {
  display: none;
}

.subreturns :nth-child(5) {
  display: none;
}

/* -----------------------responsiveness----------- */

@media screen and (min-width: 300px) and (max-width: 475px) {
  .homecontent {
    width: 100%;
    min-height: 100vh;
    /* background-color: rgb(240, 230, 230); */
  }

  .sellerfull_main {
    width: 100%;
    min-height: 100%;
  }

  .status {
    display: none;
  }

  .grossrevenue {
    display: none;
  }

  .main_returns {
    width: 100%;
    height: 50%;
    background-color: white;
  }

  .containerwidthgrowth {
    display: flex;
    gap: 3px;
    flex-direction: column;
  }

  .innerseller {
    height: 70%;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    border: 1px solid gainsboro;
    border-radius: 2px;
  }

  .fulfilled_orders {
    font-size: 15px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2px;
  }
  .secondinner{
    font-size: 10px;
    margin-top: -1rem;
    color: #666;
  }

  .fulfilled_orders p {
    font-size: 12px;
  }
  .fulfilled_orders h4,
  .innerlistings h4 {
    color: #2874f0;
    /* letter-spacing: 1px; */
    font-size: 15px;
  }

  .Orders {
    width: 100%;
    min-height: 60%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 10px;
  }

  .Orders  {
    
    /* height: 20px; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border: 0.5px solid gainsboro;
    /* flex-direction: column; */
    /* justify-content: center; */
    align-items: center;
    border-radius: 4px;
    padding: 5px;
  }
  .Order div{
    width: 50%;
  }
  /* .Orders div {
    margin-top: 20px;
    width: 50%;
    min-height: 50%;
    padding: 20px;
    display: flex;
    border: 1px solid gainsboro;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  } */

  .growth_center {
    width: 100%;
    min-height: 100vh;
    border-radius: 2px;
  }

  .innergrowth_center {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .newgrowthcenter {
    /* width: 100%;
    padding: 10px 0px 5px 0px; */
    /* height: 10%; */
    background-color: rgb(250, 212, 148);
    display: flex;
    /* flex-wrap: wrap; */
    font-size: 14px;
    /* gap: 5px; */
    justify-content: flex-start;
    padding-left: 10px;
  }

  .innnergrowth {
    font-size: 15px;
    padding-top: 10px;
  }

  .secondinner {
    font-size: 12px;
    width: 80%;
  }

  .thirdgrowth_central {
    display: flex;
    gap: 90px;
  }

  .secondgrowth_central {
    display: flex;
    justify-content: space-around;
  }

  .numbers {
    color: #2874f0;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 600;
  }

  .payments {
    width: 100%;
    height: 150px;
    padding-top: 10px;
    border: 1px solid gainsboro;
    border-radius: 2px;
    background-color: white;
  }

  .paymentscontent {
    padding-left: 20px;
  }

  .next_payment {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    gap: 100px;
    font-size: 13px;
    padding:5px;
  }

  .payments hr {
    width: 100%;
  }

  .inner_next_payment {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    line-height: 25px;
  }

  .prepaid {
    margin-top: 20px;
    display: flex;
    justify-content: space-evenly;
    gap: 180px;
  }

  .subreturns {
    padding-top: 6px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-around;
  }

  .destop_return {
    display: none;
  }

  .subreturns div {
    width: 45%;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0.5px solid gainsboro;
    border-radius: 5px;
  }

  .subreturns :nth-child(5) {
    display: none;
  }

  .listings {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .innerlistings {
    padding-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    font-size: 14px;
  }

  .innerlistings p {
    font-size: 12px;
  }

  .rupees {
    font-size: 13px;
  }

  .inner_next_payment h3 {
    font-size: 13px;
  }

  .returnNumbers {
    font-size: 15px;
    font-weight: 600;
  }

  .returnNumbers + p {
    font-size: 13px;
  }

  .paymentscontent {
    font-size: 15px;
    /* padding-left: 40px; */
  }

  .growthcentral_sub {
    height: 80px;
    width: 100%;
    padding-left: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: white;
  }

  .numbers {
    padding-right: 20px;
    color: #2874f0;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    padding-left: 5px;
  }

  .divtagmobile {
    display: block;
  }

  .divtagmobile div {
    width: 45%;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0.5px solid gainsboro;
    border-radius: 5px;
    margin-left: 3px;
  }

  .contentsmobile {
    margin-top: 10px;
  }
  .Orders1  {
    margin-top: 10px;
   
    /* min-height: 50%; */
    /* padding: 20px; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border: 1px solid gainsboro;
    /* flex-direction: column; */
    /* justify-content: center; */
    align-items: center;
    padding: 5px;
  }
  .Order1 div{
    width: 50%;
  }
  .innerorder1 {
    font-size: 23px;
    font-weight: 600;
  }
}
