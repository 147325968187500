.error_class {
  border-color: red;
  background: #ffe6e6;
}
.link_deco {
  text-decoration: none;
}
.categoriesall {
  margin-top: 10px;
  height: 50px;
  width: 120px;
  border-width: 1px;
  border-color: rgb(0, 102, 204);
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: rgba(0, 102, 204, 0.1);
}
.categoriesall1 {
  margin-top: 10px;
  height: 50px;
  width: 120px;
  border-width: 1px;
  border-color: #ccc;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  border-radius: 6px;
}
.alltext {
  font-size: 12px;
  padding-left: 6px;
  font-weight: 500;
  color: rgb(0, 102, 204);
}
.alltext1 {
  font-size: 12px;
  padding-left: 6px;
  width: 96px;
  font-weight: 500;
  /* color: rgb(0, 102, 204); */
}
.looktext {
  color: #000;
  font-weight: 600;
}
.looktext1 {
  color: #000;
  font-weight: 600;
  font-size: 12px;
}
.looktext2 {
  color: #000;
  /* font-weight: 600; */
  font-size: 13px;
}
.registercontainer {
  margin-top: 20px;
  height: 40px;
  width: 210px;
  border-width: 1px;
  border-color: rgb(0, 102, 204);
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0066cc;
  border-radius: 8px;
}
.registercontainer1 {
  margin-top: 20px;
  height: 40px;
  width: 210px;
  border-width: 1px;
  border-color: rgb(0, 102, 204);
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0066cc;
  border-radius: 8px;
}
.registertext {
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  padding-right: 10px;
  padding-top: 5px;
}
.registertext1 {
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  padding-right: 10px;
}
.pogresscontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  max-width: 600px;
}
.emailtext {
  font-size: 12px;
  color: #000;
  padding-left: 6px;
  font-weight: 400;
}
.maincontainer {
  margin: auto;
  max-width: 800px;
  margin-left: 15px;
  margin-right: 15px;
}
.textcontainer {
  margin-top: 10px;
}
.textcontainer1 {
  margin-top: 8px;
}
.continuecontainer {
  margin-top: 20px;
  height: 40px;
  width: 150px;
  border-width: 1px;
  border-color: rgb(0, 102, 204);
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0066cc;
  border-radius: 8px;
}
.continuetext {
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  padding-right: 10px;
}
.textname {
  color: "#000";
  font-size: 18px;
  font-weight: 700;
}
.textmob {
  color: "#000";
  font-size: 12px;
  font-weight: 600;
  /* padding-top: 3px; */
}
.numbercontainer {
  display: flex;
  align-items: center;
}
.numbercontainer1 {
  display: flex;
  align-items: center;
}
.gmailtext {
  color: #000;
  font-weight: 400;
  padding-left: 11px;
}
.looktext3 {
  color: #000;
  font-weight: 700;
  font-size: 13px;
}
.signaturecontainer {
  margin-top: 20px;
  height: 40px;
  width: 160px;
  border-width: 1px;
  border-color: #ccc;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
}
.signaturetext {
  color: rgb(0, 102, 204);
  font-weight: 600;
  font-size: 9px;
  padding-left: 10px;
}
.savecontainer {
  margin-top: 20px;
  height: 40px;
  width: 100px;
  border-width: 1px;
  border-color: rgb(0, 102, 204);
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0066cc;
  border-radius: 8px;
}
.listproduct {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.verifiedcontainer {
  height: 30px;
  width: 100px;
  background-color: #e6ffe6;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
.signaturetext4 {
  font-size: 17px;
  font-weight: 700;
  padding-left: 8px;
}
.submittext {
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  padding-right: 10px;
}
.submitcontainer {
  margin-top: 20px;
  height: 40px;
  width: 150px;
  border-width: 1px;
  border-color: rgb(0, 102, 204);
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0066cc;
  border-radius: 8px;
  margin-bottom: 20px;
}
.resistercontainer1 {
  display: flex;
  justify-content: space-between;
}

/* -------------listing-------------- */

.main_screen {
  padding: 0 3%;
}

.listing_header {
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgb(193, 199, 208);
  background-color: rgb(238, 238, 238);
  padding: 0 3%;
}
.listing_header .listing_header_left {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1vh;
}
.listing_header_left .list_header_left_top p {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: rgb(0, 102, 204);
  font-size: 0.8rem;
  padding: 0;
  margin: 0;
}
.listing_header_left .list_header_left_top p span {
  color: gray;
  margin-left: 0.2vh;
}
.listing_header_left .list_header_left_top .header_name {
  color: rgb(61, 70, 77);
  font-size: 1.3rem;
  font-weight: 600;
}
.listing_header .listing_header_right {
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.listing_header .listing_header_right .header-search {
  width: 60%;
}
.radio_laptop {
  margin-top: 5vh;
  display: flex;
}
.radio_laptop .radio_buttons_listing {
  height: 10vh;
  width: 25vh;
  padding: 1%;
  border-radius: 0 !important;
}
.radio_laptop .radio_buttons_listing:nth-child(5) {
  margin-left: 8vh;
}
.radio_buttons_listing .rd_btn_lst_top {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rd_btn_lst_top .lst_count {
  /* color: rgb(61, 70, 77); */
  font-size: 1.3rem;
  font-weight: 600;
}
.rd_btn_lst_top .list_info_icon {
  /* color: rgb(61, 70, 77); */
  font-size: 0.8rem;
  font-weight: 600;
}
.radio_buttons_listing .rd_btn_lst_bottom {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 500;
}

.fillering_div {
  margin-top: 5vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.fillering_div .downloade_btn {
  width: 30%;
}
.fillering_div .filter_btn {
  width: 70%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2vh;
}
.product_filtering {
  margin-top: 5vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: 1px solid rgb(193, 199, 208);
  background-color: rgb(238, 238, 238);
}
.product_filtering_grid {
  width: 100%;
  border: 1px solid rgb(193, 199, 208);
  /* background-color: rgb(238, 238, 238); */
  margin-bottom: 5vh;
}
.product_filtering .product_filtering_left {
  width: 5%;
  display: flex;
  justify-content: center;
}
.product_filtering .product_filtering_right {
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  padding: 1%;
}
.margin_class {
  margin-right: 2vh;
}

.link_registered {
  text-decoration: none;
  color: rgb(0, 102, 204);
  margin-top: 5vh;
}
.mobi_margin_only {
  padding-top: 5vh !important;
}
.mobile_logo_login {
  /* display: none; */
  /* display: block; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 1%;
}

@media screen and (max-width: 768px) {
  .link_registered {
    margin-top: 5vh !important;
  }

  /* .mobi_margin_only {
    padding-top: 5vh !important;
  } */

  .mobile_logo_login {
    display: block;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 3%;
  }
  .listing_header {
    width: 100%;
    height: 12vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .listing_header .listing_header_left {
    width: 100%;
    display: flex;
  }

  .listing_header_left .list_header_left_top {
    display: flex;
    flex-direction: row;
  }
  .listing_header_left .list_header_left_top .header_name {
    font-size: 1rem;
  }
  .listing_header .listing_header_right {
    width: 100%;
  }
  .listing_header .listing_header_right .header-search {
    width: 50%;
  }
  .radio_laptop {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2vh;
  }
  .radio_laptop .radio_buttons_listing {
    height: 10vh;
    width: 24.3vh;
    margin: 1vh;
  }
  .radio_laptop .radio_buttons_listing:nth-child(5) {
    margin-left: 1vh;
  }
  .fillering_div {
    margin-top: 1vh;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }
  .fillering_div .downloade_btn {
    width: 100%;
    margin-top: 1vh;
  }
  .fillering_div .filter_btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .product_filtering {
    margin-top: 1vh;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .product_filtering .product_filtering_left {
    display: none;
  }

  .product_filtering .product_filtering_right {
    width: 100%;
    padding: 1%;
  }
  .margin_class {
    margin-right: 1vh;
    margin-bottom: 0.5vh;
  }
}
@media screen and (max-width: 570px) {
  .resistercontainer1 {
    display: block;
  }
  .registercontainer1 {
    width: 92%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2vh;
  }
  .registercontainer {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2vh;
  }
}

/*--------ajit---*/
.mylistingcontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
}
.mylistingtext {
  font-size: 0.7rem;
  font-weight: 500;
  color: #027cd5;
}
.mylistingicon {
  font-size: 0.7rem;
  margin-top: -14px;
  padding-left: 5px;
}
.singlecontainer {
  margin-left: 5px;
  margin-top: -5px;
}
.mainselectverticalcontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.selectverticalcontainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; */
  padding: 10px;
  /* max-width: 1000px; */
}
.selectverticalcontainer1 {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  width: 200px;
}
.one {
  height: 40px;
  width: 40px;
  border: 1px solid #027cd5;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.verticaltext {
  color: #027cd5;
  font-size: 0.8rem;
  font-weight: 500;
  padding-top: 10px;
  padding-left: 10px;
}
.checkcontainer {
  padding: 5%;
  background-color: #fff;
}
.checktext {
  font-size: 13px;
}
.checkBrand {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.hr {
  border-bottom: 0.6px solid #ccc;
  width: 100%;
  margin-top: 30px;
}
.or {
  position: absolute;
  top: 43.5%;
  left: 25%;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: #fff;
  font-size: 0.7rem;
}
.selecttext {
  font-size: 13px;
  padding-top: 25px;
}
.testtext {
  color: #027cd5;
  font-size: 10px;
}
.asftext {
  color: #027cd5;
  font-size: 10px;
  margin-top: -15px;
}
.sevencontainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; */
  padding-top: 10px;
  max-width: 300px;
  /* margin-left: 10px; */
}
.checkicon {
  color: green;
  font-size: 15px;
}
.brandtext {
  font-size: 10px;
  font-weight: 500;
  width: 350px;
  background-color: #ffd591;
}
.circleicon {
  font-size: 10px;
  color: #ff9d00;
}
.boxmaincontainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.boxmaincontainer1 {
  width: 50%;
}
.boxmaincontainer2 {
  width: 50%;
}

/* -------------listing-------------- */

.main_screen {
  padding: 0 3%;
}

.listing_header {
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgb(193, 199, 208);
  background-color: rgb(238, 238, 238);
  padding: 0 3%;
}
.listing_header .listing_header_left {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1vh;
}
.listing_header_left .list_header_left_top p {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: rgb(0, 102, 204);
  font-size: 0.8rem;
  padding: 0;
  margin: 0;
}
.listing_header_left .list_header_left_top p span {
  color: gray;
  margin-left: 0.2vh;
}
.listing_header_left .list_header_left_top .header_name {
  color: rgb(61, 70, 77);
  font-size: 1.3rem;
  font-weight: 600;
}
.listing_header .listing_header_right {
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.listing_header .listing_header_right .header-search {
  width: 60%;
}
.radio_laptop {
  margin-top: 5vh;
  display: flex;
}
.radio_laptop .radio_buttons_listing {
  height: 10vh;
  width: 25vh;
  padding: 1%;
  border-radius: 0 !important;
}
.radio_laptop .radio_buttons_listing:nth-child(5) {
  margin-left: 8vh;
}
.radio_buttons_listing .rd_btn_lst_top {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rd_btn_lst_top .lst_count {
  /* color: rgb(61, 70, 77); */
  font-size: 1.3rem;
  font-weight: 600;
}
.rd_btn_lst_top .list_info_icon {
  /* color: rgb(61, 70, 77); */
  font-size: 0.8rem;
  font-weight: 600;
}
.radio_buttons_listing .rd_btn_lst_bottom {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 500;
}

.fillering_div {
  margin-top: 5vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.fillering_div .downloade_btn {
  width: 30%;
}
.fillering_div .filter_btn {
  width: 70%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2vh;
}
.product_filtering {
  margin-top: 5vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: 1px solid rgb(193, 199, 208);
  background-color: rgb(238, 238, 238);
}
.product_filtering_grid {
  width: 100%;
  border: 1px solid rgb(193, 199, 208);
  /* background-color: rgb(238, 238, 238); */
  margin-bottom: 5vh;
}
.product_filtering .product_filtering_left {
  width: 5%;
  display: flex;
  justify-content: center;
}
.product_filtering .product_filtering_right {
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  padding: 1%;
}
.margin_class {
  margin-right: 2vh;
}

.link_registered {
  text-decoration: none;
  color: rgb(0, 102, 204);
  margin-top: 5vh;
  cursor: pointer;
}
.mobi_margin_only {
  padding-top: 5vh !important;
}
.mobile_logo_login {
  display: none;
}

.info_container {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.info_container .info_container_left {
  width: 50%;
  height: 100vh;
}
.info_container_left .no_img_div {
  width: 100%;
  height: 9vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 2vh;
}
.no_img_div .image_name {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: gray;
  gap: 2vh;
}

.no_img_div .save_image_div {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 2vh;
}
.save_image_div .cancel_imag {
  width: 13vh;
  height: 6vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #0066cc;
}
.save_image_div .save_image {
  width: 13vh;
  height: 6vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 2px solid rgb(0, 102, 204);
  background: #fff;
  border-radius: 1px;
  color: #0066cc;
}

.image_array_container {
  /* width: 100%; */
  height: 19vh;
  padding: 0.5vh 2vh;
  display: flex;
  justify-content: flex-start;
  gap: 1vh;
  overflow-x: scroll;
}
.single_img_box {
  width: 15vh;
  height: 15vh;
  position: relative;
}
.single_img_box .imgbox_img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.single_img_box .image_check_icon {
  position: absolute;
  top: 0;
  right: 1vh;
}
.valid_icon {
  color: green;
}
.empty_icon {
  color: #ffcd00;
}

.image_priview_div {
  width: 100%;
  height: 70vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgpriview_img {
  width: 50%;
  height: 60vh;
}
.image_priview_div .priview_icondiv {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 7vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2vh;
}

.privie_text {
  color: #0066cc;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1vh;
}
.delete_icon {
  color: gray;
  font-size: 1.5rem;
}

.image_guidline_container {
  width: 100%;
  height: 38vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image_guidline_main {
  width: 80%;
  height: 36vh;
}

.guid_div {
  width: 100%;
  height: 27vh;
  border: 1px solid rgb(0, 102, 204);
}
.guid_div .guid_div_top {
  width: 100%;
  height: 30%;
  background: #0066cc;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 2vh;
}
.guid_div_top .image_qui_title {
  color: #000;
}

.guid_box {
  width: 100%;
  padding: 0.7vh 2vh;
  display: flex;
  flex-direction: column;
}
.guid_box .gu_title {
  font-size: 1rem;
  color: #000;
  font-weight: 600;
}
.guid_box .gu_text {
  font-size: 0.8rem;
  color: #000;
}

.info_container .info_container_right {
  width: 50%;
  display: flex;
  justify-content: center;
  overflow: scroll;
  height: 100vh;
}

/* width */
.info_container_right::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.info_container_right::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  /* border-radius: 10px; */
}

/* Handle */
.info_container_right::-webkit-scrollbar-thumb {
  background: #027cd5;
  border-radius: 1px;
}

/* Handle on hover */
.info_container_right::-webkit-scrollbar-thumb:hover {
  background: #027cd5;
}
.info_container_right .accordien {
  width: 100%;
}

.step_add_products {
  width: 100%;
  /* height: 15vh; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 3vh;
  margin-top: 20px;
}
.step_add_products .step_container {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.step_add_products .step_butn {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.timeline_box {
  width: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7vh;
}
.timeline_box p {
  text-transform: capitalize;
}

@media screen and (max-width: 768px) {
  .boxmaincontainer {
    display: block;
  }
  .boxmaincontainer1 {
    width: 100%;
  }
  .boxmaincontainer2 {
    width: 100%;
  }
  .or {
    position: absolute;
    top: 39%;
    left: 50%;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background-color: #fff;
    font-size: 0.7rem;
  }

  .image_guidline_container {
    display: none;
  }
  .image_priview_div {
    width: 100%;
    height: 60vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .info_container .info_container_right {
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: scroll;
    height: 100vh;
  }
  .total_sale {
    margin-left: -30px;
  }
}

.selectthetext {
  font-size: 0.8rem;
  font-weight: 600;
  padding-top: 10px;
}
.optionstext {
  font-size: 0.6rem;
}
.searchcontainer {
  width: 600px;
}
.savegocontainer {
  height: 7vh;
  width: 20vh;
  border: 1px solid #027cd5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-right: 10px;
}
.sendtocontainer {
  height: 7vh;
  width: 20vh;
  border: 1px solid #93b1c7;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #027cd5;
  margin-right: 10px;
}
.savegocontainertext {
  font-size: 0.8rem;
  color: #027cd5;
  padding-top: 10px;
}
.sendtocontainertext {
  font-size: 0.7rem;
  padding-top: 10px;
  color: #000;
  font-weight: 500;
}

/*----seq-----*/
.main-container {
  /* padding: 0 15px; */
}
.container-one {
  /* padding: 10px; */
}

.basic_col {
  height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 20%;
}
.item {
  padding: 10px 0;
}
.item h3 {
  font-weight: 400;
  color: gray;
  border-bottom: 1px inset;
  font-size: 15px;
  /* text-align: end;
  padding: 5px; */
  padding-left: 5vh;
  padding-bottom: 1vh;
}
.container-two {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}
.kid-card {
  display: flex;
  justify-content: space-between;
}
.kid-card span {
  font-size: medium;
  font-weight: 600;
}
.heart {
  color: red;
}
.heading {
  position: sticky;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 98;
  background-color: #fff;
  width: 100%;
}
.heading p {
  font-size: 10pt;
  color: gray;
  font-weight: bold;
  padding-left: 15px;
}
.cardcontainer2 {
  width: 18%;
  height: 100%;
  margin: 5px;
  /* width: 200px;
  height: 180px; */
}
/*----------------footerRegPart-------------*/

ul li {
  list-style: none;
  padding-top: 2px;
}
ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 12px;
}

.sellacrosscontainer {
  display: flex;
  align-items: center;
}

.shoopingcontainer {
  display: flex;
  justify-content: center;
}
.shoppingtext {
  color: #000;
  font-weight: 600;
  font-size: 22px;
}
.headingtext {
  color: #000;
  font-size: 15px;
  font-weight: 600;
  padding-left: 10px;
}
.imgbox {
  height: 50px;
  width: 45px;
  padding-bottom: 5px;
}
.listtext {
  color: #000;
  font-weight: 500;
}
.loginsidecontainer {
  border: 0.5px solid #ccc;
  background-color: #fff;
  height: 100px;
  margin: 10px;
  border-radius: 8px;
}
.loginsidecontainer1 {
  display: flex;
  /* align-items: center; */
  padding: 10px;
}
.startinhtext {
  color: #000;
  font-size: 10px;
  font-weight: 500;
  padding-left: 10px;
  width: 150px;
}
@media screen and (max-width: 750px) {
  .headingtext {
    color: #000;
    font-size: 12px;
    font-weight: 600;
  }
}

/*---------approvadpage---------*/
.returncontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f7f7;
  padding: 8px;
  border-bottom: 0.5px solid #ccc;
}
.subreturncontainer {
  display: flex;
  margin: 0px;
  justify-content: space-evenly;
  width: 60%;
}
.guidetext {
  font-size: 12px;
  color: #0066cc;
  padding-top: 8px;
}
.returncontainer1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-bottom: 0.5px solid #ccc;
}
.subreturncontainer2 {
  display: flex;
  margin: 0px;
  justify-content: space-between;
  width: 40%;
}
.requestcontainer {
  background-color: #0066cc;
  color: #fff;
  font-size: 10px;
  padding: 5px;
  border-radius: 2px;
}
.guidetext1 {
  font-size: 12px;
  color: #666;
  padding-top: 8px;
}
.returncontainer2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-bottom: 0.5px solid #ccc;
  margin: 6px;
}
.subreturncontainer3 {
  display: flex;
  margin: 0px;
  justify-content: space-between;
  width: 40%;
}
.returnstext {
  font-size: 10px;
  color: #666;
}
.return_container {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  padding: 5px;
  border-bottom: 0.5px solid #ccc;
  margin: 6px;
}
.pickuptext {
  font-size: 10px;
  color: #0066cc;
  font-weight: 600;
}
.pickuptext1 {
  border-left: 0.5px solid #ccc;
  font-size: 10px;
  color: #000;
  font-weight: 600;
  margin-left: 20px;
  padding-left: 10px;
}
.guidetext2 {
  font-size: 12px;
  color: #0066cc;
  padding-top: 8px;
}
.footerseller {
  background-color: #2f415d;
}
.footer-top {
  padding-top: 50px;
  padding-bottom: 50px;
}
.footer-top h4 {
  color: #fff;
  font-size: 16px;
  margin: 0 0 15px;
  text-transform: uppercase;
}
.footer-top ul{
  padding: 0;
  margin: -3px 0;
  margin-bottom: 10px;
}
.footer-top ul li {
  color: #ffffff;
  padding: 3px 0;
  margin: 0;
}
.footer-top ul li a{
  font-size: 14px;
  color: #ffffff;
}
.footer-top p{
  color: #fff;

}

.copytext{
  border-top: 1px solid #40516d;
  color: #ffffff;
  margin-top: 15px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 25px;

}
.copytext p{
  font-size: 14px;
}
@media screen and (max-width: 768px) {
  .link_registered {
    margin-top: 5vh !important;
  }

  .mobi_margin_only {
    /* padding-top: 5vh !important; */
  }

  .mobile_logo_login {
    display: block;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .listing_header {
    width: 100%;
    height: 12vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .listing_header .listing_header_left {
    width: 100%;
    display: flex;
  }
  .footer-top {
    padding-bottom: unset;
    padding-top: 20px;
  }
  .listing_header_left .list_header_left_top {
    display: flex;
    flex-direction: row;
  }
  .listing_header_left .list_header_left_top .header_name {
    font-size: 1rem;
  }
  .listing_header .listing_header_right {
    width: 100%;
  }
  .listing_header .listing_header_right .header-search {
    width: 50%;
  }
  .radio_laptop {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2vh;
  }
  .radio_laptop .radio_buttons_listing {
    height: 10vh;
    width: 24.3vh;
    margin: 1vh;
  }
  .radio_laptop .radio_buttons_listing:nth-child(5) {
    margin-left: 1vh;
  }
  .fillering_div {
    margin-top: 1vh;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }
  .fillering_div .downloade_btn {
    width: 100%;
    margin-top: 1vh;
  }
  .fillering_div .filter_btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .product_filtering {
    margin-top: 1vh;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .product_filtering .product_filtering_left {
    display: none;
  }

  .product_filtering .product_filtering_right {
    width: 100%;
    padding: 1%;
  }
  .margin_class {
    margin-right: 1vh;
    margin-bottom: 0.5vh;
  }
  .step_add_products .step_container {
    width: 100%;
    margin-top: 9vh;
  }
  .basic_row {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .basic_col {
    height: 30vh !important;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 70% !important;
    background-color: #fff;
    max-width: 70% !important;
    flex: 0 0 25%;
  }
  .item h3 {
    font-weight: 500;
    color: #000;
    border-bottom: 1px inset;
    font-size: 15px;
    /* width: 20vh; */
    padding-left: 2vh !important;
    padding-bottom: 1vh;
  }
  .main_field {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .info_container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .info_container .info_container_left {
    width: 100%;
    height: 78vh;
  }
  .footer-top h4{
    font-size: 15px;
  }
}
