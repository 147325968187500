/* @font-face {
    font-family: Myfont;
    src: url('axiform.ttf');
} */

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  /* font-family: Myfont; */
}

.dropdown_ant {
  color: #ffffff;
  cursor: pointer;
}
.dropdown_ant:hover .dropdown_icon {
  /* animation: rotate 1s ease-out; */
  transform: rotate(180deg);
}

/* @keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
} */

.dropdown_icon {
  font-size: 0.8rem;
  color: #ffffff;
}
.draopdown_link {
  text-decoration: none;
  padding: 0.1vh 0.5vh;
}
#nav2{
  height: 70px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
#nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 96;
  align-items: center;
  background-color: #027cd5;
  color: #ffffff;
  width: 100%;
}
.left_header {
  width: 48%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.right_header {
  width: 13%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#dropdown {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
  width: 100%;
  height: 60px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 9ch; /* Approximate width of 15 characters */
  display: inline-block;
  vertical-align: top;
}

.dropdown-menu.show {
  animation: sweep 0.5s ease-in-out;
}

#nav h6 {
  font-size: 14px;
  width: 100%;
  font-weight: 500;
  line-height: 1;
  cursor: pointer;
  vertical-align: middle;
  margin-top: 10px;
  color: #ffffff;
}

.responsive {
  display: none;
}

.Homeheading {
  height: 65px;
  background-color: #f3e8bc;
  display: flex;
  align-items: center;
  justify-content: center;
}
#section {
  height: 65px;
  border-bottom: 1px solid #a79ea3;
  display: flex;
  align-items: center;
}
#book .card {
  height: 450px;
  margin: 10px;
  cursor: pointer;
}

@keyframes sweep {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.nav-sticky {
  margin: 0;
  padding: 0;
  position: sticky;
  background-color: #ffffff;
  top: 0;
  left: 0;
  right: 0;
  z-index: 95;
}

@media screen and (max-width: 768px) {
  * {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
  }

  .menu-list {
    padding: 0 20px;
  }

  .menu-item {
    display: flex;
    align-items: center;
    padding: 15px 0;
    font-size: 16px;
  }

  .menu-item span {
    margin-left: 10px;
    color: #333;
  }

  .menu-item svg {
    font-size: 20px;
    color: #027cd5;
  }

  .menu-item:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }

  @keyframes slideInLeft {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideOutLeft {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(-100%);
    }
  }

  .custom-drawer-enter {
    animation: slideInLeft 0.6s forwards;
  }

  .custom-drawer-leave {
    animation: slideOutLeft 0.6s forwards;
  }

  #nav {
    display: flex;
    margin: 10px;
    top: 0;
    z-index: 96;
    align-items: flex-start;
  }

  .responsive {
    display: block;
  }

  #nav2 {
    display: none;
  }

  .profile {
    position: sticky;
    position: -webkit-sticky;
    width: 100%;
    top: 0;
    left: 0;
    height: 50px;
    display: block;
    z-index: 97;
  }

  .ant-drawer .ant-drawer-body {
    padding: 0%;
  }

  .threebars {
    font-size: 30px;
  }

  ::-webkit-scrollbar {
    width: 10px;
    /* Width of the scrollbar */
    display: none;
  }
}

@media screen and (max-width: 1087px) {
  #nav {
    display: flex;
    margin: 12px;
    top: 0;
    z-index: 96;
    align-items: center;
    justify-content: space-between;
  }

  #nav h6 {
    font-size: 10px;
    width: 100%;
    font-weight: 500;
    line-height: 1;
    vertical-align: middle;
    margin-top: 10px;
    color: #ffffff;
  }

  .logo {
    color: #ffffff;
    font-size: 10pt;
    text-align: center;
  }
}

@media screen and (max-width: 850px) {
  #nav {
    display: flex;
    margin: 10px;
    top: 0;
    z-index: 96;
    align-items: center;
    justify-content: space-between;
  }

  #nav h6 {
    font-size: 8px;
    width: 100%;
    font-weight: 500;
    line-height: 1;
    vertical-align: middle;
    margin-top: 10px;
    color: #ffffff;
  }
}

@media (max-width: 320px) {
  .custom-drawer .custom-drawer-body {
    width: 100px !important;
  }

  .ant-drawer .ant-drawer-content {
    width: 75%;
  }

  .ant-drawer-left > .ant-drawer-content-wrapper {
    box-shadow: none;
  }

  @keyframes slideInLeft {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideOutLeft {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(-100%);
    }
  }

  .custom-drawer-enter {
    animation: slideInLeft 0.6s forwards;
  }

  .custom-drawer-leave {
    animation: slideOutLeft 0.3s forwards;
  }
}
